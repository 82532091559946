import React from "react";
import HomeBanner from '../components/HomeBanner';
import WhatWeDo from '../components/WhatWeDo';
import FeaturedWorkSlider from '../components/FeaturedWorkSlider';
import Gallery from '../components/HomeGallery';
import FollowMilkbar from '../components/FollowMilkbar';
import ServiceTabsPanel from "../components/ServiceTabsPanel";

function App() {
  return (
    <>
      <HomeBanner />
      <WhatWeDo />
      <FeaturedWorkSlider />
      <Gallery />
      <ServiceTabsPanel />
      <FollowMilkbar />            
    </>
  );
}

export default App;

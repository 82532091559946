import React from "react";
import ProjectBanner from '../components/ProjectBanner';
import WhatWeDo from '../components/WhatWeDo';
import BrandDirection from '../components/BrandDirection';
import Gallery from '../components/HomeGallery';
import SeamlessBooking from '../components/SeamlessBooking';
import ProjectVerticle from '../components/ProjectVerticle';
import MoreProjectSlider from '../components/MoreProjectSlider';

function App() {
  return (
    <>
      <ProjectBanner />
      <WhatWeDo />
      <BrandDirection />
      <Gallery />
      <SeamlessBooking />
      <ProjectVerticle />       
      <MoreProjectSlider /> 
    </>
  );
}

export default App;

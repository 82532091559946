import React from "react";
import BrandLogo from '../assets/images/meso.svg';

function brandDirection(){
    return(
        <>
        <div className="brand_direction position-relative">            
            <div className="text-center fw-200">                
                <h6 className="text-uppercase m-0">Brand Direction</h6>  
                <div className="dotdivider text-center"><span></span></div>  
                <p className="font-messina">Meso is an Athens-based yacht charter company serving HNW clientele in Greece, Turkey, Spain, Albania, and more. We wanted to reference the Greek routes in the brand design while maintaining a simple look and feel to reflect the ease of booking with Meso.</p>
                <div className="brand_logo d-flex align-items-center justify-content-center nowrap">
                    <div className="common_brand_logo">
                        <img src={BrandLogo} alt="Logo" />
                    </div>
                    <div className="common_brand_logo active">
                        <img src={BrandLogo} alt="Logo" />
                    </div>
                    <div className="common_brand_logo">
                        <img src={BrandLogo} alt="Logo" />
                    </div>
                </div>
            </div>             
        </div>        
        </>
    );
}
export default brandDirection;
import React from "react";
import VerticleImage1 from '../assets/images/anthen-verticle-image1.jpg';
import VerticleImage2 from '../assets/images/anthen-verticle-image2.jpg';

function projectVerticle(){
    return(
    <div className="bg-white pb-120">
        <div className="project_verticle_images d-grid">  
            <div className="project_verticle_image position-relative">  
                <img src={VerticleImage1} alt="Logo" className="object-fit-cover" />
                <div className="verticle_overlap_text position-absolute">
                    <p className="font-messina">A flawless mobile booking system</p>
                </div>
            </div>
            <div className="project_verticle_image position-relative">  
                <img src={VerticleImage2} alt="Logo" className="object-fit-cover" />
                <div className="verticle_overlap_text position-absolute">
                    <p className="font-messina">A flawless mobile booking system</p>
                </div>
            </div>         
        </div>
    </div>    
  );
}
export default projectVerticle; 
import React, { useRef, useState } from 'react';
import Item1 from '../assets/images/service-slide-1.jpg';
import Item2 from '../assets/images/gulet.jpeg';
import Item3 from '../assets/images/home-banner.jpg';
import Item4 from '../assets/images/social-media-banner.jpg';
import Item5 from '../assets/images/work-3.jpg';
import LeftArrow from '../assets/images/left-arrow.svg';
import RightArrow from '../assets/images/right-arrow.svg';
import TabArrow from '../assets/images/right_arrow_icon.svg'
import { Link } from "react-router-dom";
import { Navigation, EffectFade, Scrollbar, A11y, Thumbs, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';

function ServiceTabsPanel() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <div className="services_tabs_panel position-relative pb-120">
                <h6 className="text-uppercase letter-spacing-5 text-left">Services</h6>
                <div className="service_tabs_wrapper position-relative">
                    <div className='service_tab_items'>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={true}
                            direction={'horizontal'}
                            spaceBetween={0}
                            slidesPerView={'auto'}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            centeredSlides={true}
                            slideToClickedSlide={true}
                            breakpoints={{
                                769: {
                                  slidesPerView: 5,
                                  spaceBetween: 20,
                                  direction: 'vertical',
                                  centeredSlides: 'false',
                                  loop: 'true'
                                }
                              }} 
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>consulting</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>web and app design</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item '>
                                    <div className='service-name'>
                                        <h3>branding</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>brand design and development</strong> from logo design to groud-up concept
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>development</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>social media</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='service_tab_images'>
                        <Swiper
                            loop={true}
                            spaceBetween={10}
                            navigation={false}
                            effect={'fade'}
                            fadeEffect={{ crossFade: true }}
                            thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                            navigation={{ nextEl: ".service-arrow-right", prevEl: ".service-arrow-left" }}
                            modules={[FreeMode, EffectFade, Navigation, Thumbs, A11y, Scrollbar]}
                            scrollbar={{ draggable: true, el: '.service-swiper-custom-scrollbar' }}
                            className="mySwiper2"
                        >
                            <SwiperSlide>
                                <div className='mobile_service_content showonmobile'>
                                    <div className='service-name'>
                                        <h3>consulting</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                                <img src={Item1} alt="Service Image" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className='mobile_service_content showonmobile'>
                                    <div className='service-name'>
                                    <h3>web and app design</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                                <img src={Item2} alt="Service Image" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className='mobile_service_content showonmobile'>
                                    <div className='service-name'>
                                    <h3>branding</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                                <img src={Item3} alt="Service Image" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className='mobile_service_content showonmobile'>
                                    <div className='service-name'>
                                    <h3>development</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                                <img src={Item4} alt="Service Image" />
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className='mobile_service_content showonmobile'>
                                    <div className='service-name'>
                                    <h3>social media</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                                <img src={Item5} alt="Service Image" />
                            </SwiperSlide>
                            
                        </Swiper>
                    </div>

                    <div className="slider_nav d-flex align-items-center justify-content-center nowrap">
                        <button className="service-arrow-left arrow common_slider_arrow d-flex align-items-center justify-content-center">
                            <img src={LeftArrow} alt="Left Arrow" />
                        </button>
                        <div className="service-swiper-custom-scrollbar slider_scrollbar"></div>
                        <button className="service-arrow-right arrow common_slider_arrow d-flex align-items-center justify-content-center">
                            <img src={RightArrow} alt="Right Arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceTabsPanel;

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/css/App.css';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Work from './pages/Work';
import About from './pages/About';
import Project from './pages/Project';
import SocialMedia from './pages/SocialMedia';

function App() {
  return (    
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes> 
        <Route index element={<Home />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        <Route path="work" element={<Work />} />
        <Route path="about" element={<About />} />
        <Route path="project" element={<Project />} />
        <Route path="social-media" element={<SocialMedia />} />
      </Routes> 
      </BrowserRouter>
  );
}

export default App;

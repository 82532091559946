import React from "react";

function whatWeDo(){
    return(
        <>
        <div className="what_we_do position-relative">            
            <div className="plr-100 text-center fw-200">                
                <h6 className="text-uppercase m-0">What We Do</h6>  
                <div className="dotdivider text-center"><span></span></div>  
                <p>branding & brand direction</p>
                <p>web and app design</p>
                <p>development</p>
            </div>             
        </div>        
        </>
    );
}
export default whatWeDo;